@import "src/client_customizations/scss/client_master.scss";

.Main {
  min-height: $Main-min-height;
  // background: -webkit-gradient(
  //   linear,
  //   left bottom,
  //   left top,
  //   from(#dfead5),
  //   to(transparent)
  // );
  // background-image: linear-gradient(0deg, #dfead5, transparent);
}
