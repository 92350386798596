@import "src/client_customizations/scss/client_master.scss";

.IncentivePreferences {
	.h5 {
              display: block;
              margin:10px 0;
              font-size:14px;

		.badge {
			font-size: 50%;
			vertical-align: middle;
		}
	}
	.tooltip-bubble {
		min-width: 250px;
		max-width: 250px;
	}
	.input-well {
		background-color: $IncentivePreferences-input-well-background-color;
		padding: 0;
		border-radius: 0;
		border: none;
		.btn {
			width: 100%;
                     color:black;
                     font-size:14px;
		}

              .btn-ae {
                     width:100%;
                     padding: 10px 40px;
                     background-color: #fff;
                     background-image: linear-gradient(90deg, #6ca943, #03783d);
                     border-radius: 5px;
                     margin: 25px auto;
                     display: block;
                     font-size:16px;
              }
	}

       .form-col-custom {
              margin:0;
              padding:0;
              display:flex;

              .no-padding-left {
                     padding-left:0;
              }

              .no-padding-right {
                     padding-right:0;
              }
       }
}
