
@import "src/client_customizations/scss/client_master.scss"; 

$color_1: #07679b;
$color_2: #666666;
$color_3: #f15c2a;
$color_4: #fff;
$font_family_1: "Source Sans Pro", sans-serif;
$background_color_1: #fff;
$background_color_2: white;
$background_color_3: #f2f2f2;
$background_color_4: #5ca4d1;
$background_color_5: #f3f4f4;
$background_color_6: #0071b7;
$border_color_1: transparent;
$border_bottom_color_1: rgba(0, 0, 0, 0.25);
$border_bottom_color_2: #fff;

body {
	background-color: $background_color_1;
	font-family: $font_family_1;
	font-size: 13px;
}
h1 {
	font-size: 22px;
}
.h1 {
	font-size: 22px;
}
h2 {
	font-size: 18px;
}
.h2 {
	font-size: 18px;
}
.modal-title {
	font-size: 18px;
}
h3 {
	font-size: 14px;
}
.h3 {
	font-size: 14px;
}
h4 {
	font-size: 12px;
}
.h4 {
	font-size: 12px;
}
h5 {
	font-size: 10px;
}
.h5 {
	font-size: 10px;
}
.lead {
	font-size: 16px;
}
a {
	color: $color_1;
}
.align-center {
       display: flex;
       justify-content: center;
}
table {
	table-layout:fixed;
	tbody {
		th {
			font-weight: normal;
		}
		tr {
			th {
				&:first-child {
					text-align: left;
				}
			}
		}
	}
	tfoot {
		tr {
			th {
				&:first-child {
					text-align: left;
				}
			}
		}
	}
}
table.table-bordered {
	tfoot {
		td {
			font-weight: bold;
			border-top: 2px solid #dee2e6;
		}
		th {
			font-weight: bold;
			border-top: 2px solid #dee2e6;
		}
	}
}
button.btn-sm {
	font-size: 80%;
}

.form-container {
       &.flex {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap:15px;
       }

       &.mb-0 {
              margin-bottom: 0 !important;
       }

       &.input-col-2 {
              .form-group {
                     flex-basis: 50%;
              }
       }
}

.input-group {
       input[type="text"] {
              width: 100%;
              border: 1px solid #ced4da;
              padding: 0.375rem 0.75rem;
              border-radius: 0.25rem;
              font-size: 1rem;
       }
}

.input-well {
	.button.btn-sm {
		font-size: 80%;
	}
	background-color: $background_color_2;
	padding: 25px;
	border-radius: 4px;
	border: 1px solid #dbdbdb;
	.form-group {
		margin-bottom: 1.5rem;

              &.spaced {
                     margin-top: 8px;
                     margin-bottom: 1rem;
              }

		p {
			margin-top: 8px;
			margin-bottom: 1rem;
		}
	}
	form {
		.form-group {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	label {
		font-weight: 700;
		font-size: 12px;
		color: black;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.label-style {
		font-weight: 700;
		font-size: 12px;
		color: black;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.btn {
		background-color: $background_color_3;
		display: inline-block;
		border: 2px solid #dbdbdb;
		font-weight: 400;
		font-size: 13px;
	}
	.btn.active {
		background-color: #fff !important;
		border: 2px solid #6da943;
	}
	.btn-block-container {
		.btn {
			display: block;
			width: 100%;
			margin-bottom: 6px;
			text-align: left;
			.badge {
				background-color: $background_color_2;
				border: 1px solid #dbdbdb;
				margin-right: 5px;
				img {
					width: 20px;
				}
			}
		}
	}
	.btn-grid-container {
		display: flex;
		flex-wrap: wrap;
		button {
			width: 48%;
			margin: 2%;
			&:nth-of-type(2n) {
				margin-right: 0;
			}
			&:nth-of-type(2n +1) {
				margin-left: 0;
					}
		}
	}
	.btn-ae {
		padding: 6px 12px;
		border-radius: 0;
		border: 0;
		color: $color_4;
		font-weight: 600;
		background-color: $background_color_4;
	}
}
.btn-default {
	border: 1px solid #ced4da;
}
strong {
	font-weight: 500;
}
hr {
	border-top: 2px solid rgba(0, 0, 0, 0.8);
	margin: 0;
	padding: 0;
}
.arrow-up {
	top: calc((1rem - 1px) * -1);
	position: absolute;
	display: block;
	width: 1rem;
	height: 1rem;
	margin: 0 0.3rem;
	&:before {
		position: absolute;
		display: block;
		content: "";
		border-color: $border_color_1;
		border-style: solid;
		top: 0;
		border-width: 0 1rem 1rem 1rem;
		border-bottom-color: $border_bottom_color_1;
	}
	&:after {
		position: absolute;
		display: block;
		content: "";
		border-color: $border_color_1;
		border-style: solid;
		top: 1px;
		border-width: 0 1rem 1rem 1rem;
		border-bottom-color: $border_bottom_color_2;
	}
}
.background-grey {
	background-color: $background_color_5;
}
.bottom-thick-margin {
	border-bottom: 2px solid #000000;
}
.color-primary {
	color: $color_3;
}
.btn-ae {
	padding: 6px 12px;
	border-radius: 0;
	border: 0;
	color: $color_4;
	font-weight: 600;
	background-color: $background_color_4;
	&:hover {
		border-radius: 0;
		color: $color_4;
		font-weight: 600;
		text-decoration: none;
		background-color: $background_color_6;
	}
}
.PageLayout {
	a.btn-ae {
		&:hover {
			border-radius: 0;
			color: $color_4;
			font-weight: 600;
			text-decoration: none;
			background-color: $background_color_6;
		}
	}
}
a.btn-ae {
	&:hover {
		border-radius: 0;
		color: $color_4;
		font-weight: 600;
		text-decoration: none;
		background-color: $background_color_6;
	}
}

.backToEv {
    display: flex;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
    .backButton {
    background: linear-gradient(270deg, #05793D 4.6%, #69A843 100%);
    border: none;
    cursor: pointer;
    border-radius: 8px;
	margin-left: 2%;
	display:none;
    }
    .buttonUsedMargin {
      margin-bottom: 30px;
    }
    .buttonNewMargin {
      margin-bottom: 10px;
    }
    @media (max-width: 576px) {
      .backButton {
        width: 65%;
        }
      .buttonUsedMargin {
        margin-bottom: 10px;
        }
      .buttonNewMargin {
        margin-bottom: 10px;
        }
      .backText {
        padding: 10px 15px 10px 15px;
        }
    }
  }
  .backText {
    font-size: 20px;
    color: white;
    font-weight: 600;
    margin:auto;
    padding: 15px 45px 15px 45px;
  }

  .incentives-disclaimer {
       font-size:14px;
  }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* IE10+ CSS */
	.select-ae {
		background-size: 0;
	}
 }
