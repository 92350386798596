

/* Incentives Page */
.incentives-page {
       h1 {
           text-align: center;
           font-size:45px;
           font-weight: 100;
           margin:20px 0;
       }

       .divider {
            width:115px;
            height:4px;
            background-color: #6ca943;
            background-image: linear-gradient(90deg, #6ca943, #03783d);
            display: block;
            margin:0 auto 20px;
        }
   
       p.lead {
           font-size:20px;
           max-width:600px;
           margin:0 auto;
           text-align: center;
           font-weight: 400;
       }
   
       .btn-ae {
           padding:10px 40px;
           background-color: #fff;
           background-image: linear-gradient(90deg, #6ca943, #03783d);
           border-radius: 5px;
           margin:25px auto;
           display:block;
       }

       .print-button {
           margin:0 auto;
           display: block;
           text-align: center;
           font-size:14px;
           text-decoration: underline;

           span {
               cursor: pointer;
           }
       }

       .IncentiveCard {
            flex-basis: 24%;
            max-width: 270px;
        }


       @media(max-width:1199px) {
            .IncentiveCard {
                flex-basis: 49%;
                max-width: 279px;
            }
       }

       @media(max-width:991px) {
        .IncentiveCard {
            max-width: none;
        }
   }

       @media(max-width:768px) {
        .IncentiveCard {
            flex-basis: auto;
        }
   }
   
       .IncentiveCard {
           &.evc-card {
               border-radius: 0px;
               border:none;
               border-top:10px solid #EDD026;
               box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.25);
               margin-bottom:30px;
   
               .IncentiveCardBody {
                   .card-title {
                       color:#333;
                       font-size:30px;
                   }
               }
           }
       }
   }
   
   
   
   .incentive-details-print-only {
       display: none;
       @media print {
           display:block;
       }
       p {
           font-size:20px;
       }
   }