@import "src/client_customizations/scss/client_master.scss";

.IncentiveCard {
	// flex-basis: 24%;
	margin-right: 1%;
	margin-bottom: 1%;
	border-radius: $IncentiveCard-card-border-radius;
}
.IncentiveCardBody {
	padding: 1rem;
	text-align: center;
	color: $IncentiveCard-card-body-color;
	border-radius: 0;
	.card-title {
		margin-top: 1rem;
		color: $IncentiveCard-card-title-color;
	}
	img {
		margin-top: -12px;
		margin-bottom: -16px;
		width: 60px;
	}
}
.IncentiveCardBottom {
	padding: 1rem;
	text-align: center;
}
@media (min-width: 768px) {
	// .IncentiveCard {
	// 	flex-basis: 32%;
	// }
	// .IncentiveCardBody {
	// 	padding: 1.5rem;
	// }
	// .IncentiveCardBottom {
	// 	padding: 1.5rem;
	// }
}
